* {
  box-sizing: border-box;
}

:root {
  --font-body: Montserrat, Arial, sans-serif;
  --full-width-vw: 100vw;
  --content-width: 680px;
}

body {
  background-color: var(--color-bg);
  color: var(--color-text);
  font-family: var(--font-body);
  font-size: 16px;
  overflow-x: hidden;
  line-height: 1.5;
}

h2 {
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.1;
}

p {
  margin: 2rem 0;
}

a {
  color: white;
}
